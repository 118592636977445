import classNames from 'classnames';

const Vegetarian = ({ className = '' }) => {
  return (
    <svg
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('dietary-tag', className)}
      role="presentation"
      pointerEvents="none"
    >
      <title>Vegetarian</title>
      <path
        d="M6.47155 1.06325C6.33404 0.957303 6.16479 0.900892 5.99121 0.903141V15.0998C6.16475 15.1018 6.33392 15.0454 6.47155 14.9397C7.69321 14.0009 10.8683 11.2496 10.8683 8.00147C10.8683 4.75332 7.69321 2.00151 6.47155 1.06325Z"
        className="fill-green-70"
      />
      <path
        d="M6.00023 15.5C5.74142 15.5001 5.48981 15.4148 5.28452 15.2572C3.91556 14.2052 0.731445 11.3931 0.731445 8.00138C0.731445 4.60966 3.91556 1.79699 5.28506 0.742912C5.49019 0.585377 5.74159 0.5 6.00023 0.5C6.25887 0.5 6.51027 0.585377 6.7154 0.742912C8.08437 1.79379 11.2685 4.60539 11.2685 8.00138C11.2685 11.3974 8.08436 14.2052 6.71486 15.2572C6.51 15.4148 6.25872 15.5002 6.00023 15.5ZM6.00023 1.30333C5.91781 1.30328 5.83768 1.33047 5.77233 1.3807C4.7775 2.1439 1.53201 4.87865 1.53201 8.00138C1.53201 11.0371 4.4973 13.6427 5.77233 14.6194C5.83761 14.6695 5.91764 14.6967 5.99996 14.6967C6.08229 14.6967 6.16231 14.6695 6.22759 14.6194C7.50262 13.64 10.4679 11.0345 10.4679 8.00138C10.4679 4.87865 7.22242 2.1439 6.22759 1.3807C6.16238 1.33058 6.08247 1.3034 6.00023 1.30333Z"
        className="fill-green-130"
      />
      <path
        d="M6.00032 15.4242C5.89416 15.4242 5.79235 15.382 5.71728 15.307C5.64221 15.2319 5.60004 15.1301 5.60004 15.024V0.978835C5.60004 0.872673 5.64221 0.770857 5.71728 0.69579C5.79235 0.620722 5.89416 0.578552 6.00032 0.578552C6.10648 0.578552 6.20829 0.620722 6.28336 0.69579C6.35843 0.770857 6.4006 0.872673 6.4006 0.978835V15.024C6.4006 15.1301 6.35843 15.2319 6.28336 15.307C6.20829 15.382 6.10648 15.4242 6.00032 15.4242Z"
        className="fill-green-130"
      />
      <path
        d="M5.98638 12.121L0.982319 7.11644C0.944071 7.07953 0.913558 7.03539 0.892557 6.98656C0.871556 6.93773 0.860489 6.88521 0.860002 6.83206C0.859515 6.77891 0.869617 6.7262 0.889721 6.677C0.909824 6.6278 0.939525 6.58309 0.977091 6.54549C1.01466 6.50789 1.05934 6.47815 1.10852 6.458C1.1577 6.43785 1.21041 6.42769 1.26355 6.42813C1.3167 6.42856 1.36923 6.43958 1.41808 6.46054C1.46693 6.48149 1.51111 6.51197 1.54805 6.55018L5.98638 10.9885L10.4471 6.52777C10.4841 6.48956 10.5283 6.45908 10.5771 6.43813C10.6259 6.41717 10.6785 6.40615 10.7316 6.40571C10.7848 6.40528 10.8375 6.41544 10.8867 6.43559C10.9358 6.45574 10.9805 6.48548 11.0181 6.52308C11.0557 6.56068 11.0854 6.60539 11.1055 6.65459C11.1256 6.70379 11.1357 6.7565 11.1352 6.80964C11.1347 6.86279 11.1236 6.91532 11.1026 6.96415C11.0816 7.01297 11.0511 7.05712 11.0129 7.09403L5.98638 12.121Z"
        className="fill-green-130"
      />
      <path
        d="M5.9863 7.43942L2.54814 4.00177C2.47284 3.92668 2.43045 3.82475 2.4303 3.7184C2.43015 3.61206 2.47225 3.51001 2.54734 3.43471C2.62243 3.3594 2.72436 3.31703 2.83071 3.31688C2.93705 3.31673 3.03911 3.35883 3.11441 3.43392L5.9863 6.30581L8.87314 3.41842C8.91032 3.38124 8.95446 3.35176 9.00304 3.33164C9.05162 3.31152 9.10368 3.30115 9.15627 3.30115C9.20885 3.30115 9.26092 3.31152 9.3095 3.33164C9.35808 3.35176 9.40222 3.38124 9.4394 3.41842C9.47658 3.4556 9.50607 3.49975 9.5262 3.54833C9.54632 3.59691 9.55668 3.64898 9.55668 3.70156C9.55668 3.75414 9.54632 3.80621 9.5262 3.85479C9.50607 3.90337 9.47658 3.94752 9.4394 3.9847L5.9863 7.43942Z"
        className="fill-green-130"
      />
    </svg>
  );
};

Vegetarian.propTypes = {
  className: PropTypes.string,
};

export default Vegetarian;
