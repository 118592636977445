import classNames from 'classnames';

const Mediterranean = ({ className = '' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('dietary-tag', className)}
    role="presentation"
    pointerEvents="none"
  >
    <title>Mediterranean</title>
    <path
      d="M10.4102 4.98518C16.1886 4.59081 20.2501 8.6582 21.871 11.2257C21.9613 11.3687 21.8054 11.5278 21.6552 11.45C15.8387 8.43767 12.1856 6.5933 10.2894 5.56262C10.0062 5.40868 10.0886 5.00712 10.4102 4.98518Z"
      className="fill-green-70"
    />
    <path
      d="M5.48968 18.7411C10.6187 16.7526 16.8328 14.2706 20.2121 12.9967C12.0078 11.9085 7.07108 15.7382 5.19705 18.3235C5.00576 18.5874 5.20111 18.8531 5.48968 18.7411Z"
      className="fill-green-70"
    />
    <path
      d="M18.3914 26.1468C21.277 27.0403 24.4763 24.9868 25.5373 21.5602C26.5983 18.1337 25.1193 14.6316 22.2338 13.7381C19.3482 12.8446 16.1489 14.898 15.0879 18.3246C14.0268 21.7512 15.5059 25.2533 18.3914 26.1468Z"
      className="fill-green-70"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6872 25.1917C20.898 25.8762 23.6348 24.324 24.5821 21.2646C25.5294 18.2052 24.1487 15.378 21.938 14.6935C19.7273 14.0089 16.9905 15.5611 16.0432 18.6205C15.0958 21.6799 16.4766 24.5071 18.6872 25.1917ZM18.3915 26.1469C21.277 27.0404 24.4763 24.9869 25.5374 21.5604C26.5984 18.1338 25.1193 14.6317 22.2338 13.7382C19.3482 12.8447 16.149 14.8981 15.0879 18.3247C14.0269 21.7513 15.506 25.2534 18.3915 26.1469Z"
      className="fill-green-130"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3194 11.2314C20.675 8.41826 16.2436 3.70441 9.7472 4.58879C9.25067 4.65639 8.88467 5.17335 9.096 5.69343C10.0042 7.92799 13.3141 11.5023 20.6426 12.2679C20.5075 12.3391 20.3721 12.4088 20.2369 12.4764C11.5091 11.3719 6.30439 15.7322 4.5337 18.5079C4.23694 18.9731 4.38211 19.4753 4.76591 19.6285C8.0752 20.9503 11.4155 20.1553 14.2233 18.635C14.5445 18.4611 14.859 18.2775 15.166 18.0865C15.3669 17.5042 15.6303 16.9643 15.9433 16.4747C15.3105 16.9369 14.6329 17.3738 13.9211 17.7592C11.5552 19.0403 8.82587 19.7467 6.07622 18.946L19.7578 13.6737C19.8691 13.7062 19.9922 13.7003 20.1059 13.6482C20.1677 13.6199 20.2297 13.5911 20.2917 13.5617C20.3051 13.5602 20.3185 13.5587 20.3319 13.5572C20.3384 13.5493 20.3449 13.5413 20.3513 13.5335C20.8138 13.3129 21.2799 13.0671 21.7337 12.8117L21.0383 15.9147L22.0141 16.1333L22.9155 12.1108C23.9425 11.4724 24.8069 10.8612 25.2698 10.5095C25.4897 10.3425 25.5325 10.0289 25.3655 9.80893C25.1985 9.58906 24.8848 9.54619 24.6649 9.71326C24.2171 10.0534 23.3768 10.6467 22.3831 11.2643L22.3194 11.2314ZM11.1769 5.48202C15.4275 5.42261 18.6236 7.98019 20.4383 10.2608L11.1769 5.48202ZM20.0623 11.1921C14.5872 10.4597 11.7471 8.07566 10.5455 6.28149L20.0623 11.1921ZM18.0529 13.3277L6.06227 17.9484C7.9128 15.8139 11.8461 13.0911 18.0529 13.3277Z"
      className="fill-green-130"
    />
    <path
      d="M21.0906 17.1414C21.9473 17.4067 22.7888 17.1466 22.9703 16.5604C23.1518 15.9743 22.6045 15.2841 21.7479 15.0189C20.8912 14.7536 20.0496 15.0137 19.8682 15.5998C19.6867 16.186 20.234 16.8762 21.0906 17.1414Z"
      className="fill-green-130"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6667 19.1411C23.9406 19.1768 24.1336 19.4277 24.098 19.7015C23.9802 20.6058 23.3356 22.7009 21.6208 23.9061C21.3949 24.0649 21.083 24.0104 20.9242 23.7845C20.7654 23.5586 20.8199 23.2467 21.0458 23.088C22.4414 22.1071 23.0076 20.3306 23.1063 19.5723C23.142 19.2985 23.3929 19.1054 23.6667 19.1411Z"
      className="fill-green-130"
    />
  </svg>
);

Mediterranean.propTypes = {
  className: PropTypes.string,
};

export default Mediterranean;
