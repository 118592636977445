import classNames from 'classnames';

const ProteinPacked = ({ className = '' }) => {
  return (
    <svg
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('dietary-tag', className)}
      role="presentation"
      pointerEvents="none"
    >
      <title>Protein-Packed</title>
      <g clipPath="url(#clip0_1188_51109)">
        <path
          d="M5.875 0.75H4.375C4.16789 0.75 4 0.917893 4 1.125V10.875C4 11.0821 4.16789 11.25 4.375 11.25H5.875C6.08211 11.25 6.25 11.0821 6.25 10.875V1.125C6.25 0.917893 6.08211 0.75 5.875 0.75Z"
          className="fill-green-70"
        />
        <path
          d="M2.125 3H3.25V9H2.125C1.91789 9 1.75 8.83211 1.75 8.625V3.375C1.75 3.16789 1.91789 3 2.125 3Z"
          className="fill-green-70"
        />
        <path
          d="M15.625 0.75H14.125C13.9179 0.75 13.75 0.917893 13.75 1.125V10.875C13.75 11.0821 13.9179 11.25 14.125 11.25H15.625C15.8321 11.25 16 11.0821 16 10.875V1.125C16 0.917893 15.8321 0.75 15.625 0.75Z"
          className="fill-green-70"
        />
        <path
          d="M17.875 9H16.75V3H17.875C18.0821 3 18.25 3.16789 18.25 3.375V8.625C18.25 8.83211 18.0821 9 17.875 9Z"
          className="fill-green-70"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.375 0.75H5.875C6.08211 0.75 6.25 0.917893 6.25 1.125V10.875C6.25 11.0821 6.08211 11.25 5.875 11.25H4.375C4.16789 11.25 4 11.0821 4 10.875V9.75V2.25V1.125C4 0.917893 4.16789 0.75 4.375 0.75ZM3.25 2.25V1.125C3.25 0.50368 3.75368 0 4.375 0H5.875C6.49632 0 7 0.50368 7 1.125V4.5H13V1.125C13 0.50368 13.5037 0 14.125 0H15.625C16.2463 0 16.75 0.50368 16.75 1.125V2.25H17.875C18.4963 2.25 19 2.75368 19 3.375V4.70067C19.4484 4.96003 19.75 5.44479 19.75 6C19.75 6.55521 19.4484 7.03997 19 7.29933V8.625C19 9.24632 18.4963 9.75 17.875 9.75H16.75V10.875C16.75 11.4963 16.2463 12 15.625 12H14.125C13.5037 12 13 11.4963 13 10.875V7.5H7V10.875C7 11.4963 6.49632 12 5.875 12H4.375C3.75368 12 3.25 11.4963 3.25 10.875V9.75H2.125C1.50368 9.75 1 9.24632 1 8.625V7.29933C0.551649 7.03997 0.25 6.55521 0.25 6C0.25 5.44479 0.551649 4.96003 1 4.70067V3.375C1 2.75368 1.50368 2.25 2.125 2.25H3.25ZM7 6.75H13V5.25H7V6.75ZM3.25 3H2.125C1.91789 3 1.75 3.16789 1.75 3.375V8.625C1.75 8.83211 1.91789 9 2.125 9H3.25V3ZM14.125 0.75H15.625C15.8321 0.75 16 0.917893 16 1.125V2.25V9.75V10.875C16 11.0821 15.8321 11.25 15.625 11.25H14.125C13.9179 11.25 13.75 11.0821 13.75 10.875V1.125C13.75 0.917893 13.9179 0.75 14.125 0.75ZM16.75 9H17.875C18.0821 9 18.25 8.83211 18.25 8.625V3.375C18.25 3.16789 18.0821 3 17.875 3H16.75V9Z"
          className="fill-green-130"
        />
      </g>
      <defs>
        <clipPath id="clip0_1188_51109">
          <rect width="20" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ProteinPacked.propTypes = {
  className: PropTypes.string,
};

export default ProteinPacked;
