import * as addresses from './endpoints/addresses';
import * as baskets from './endpoints/baskets';
import * as behavioralDiscounts from './endpoints/behavioralDiscounts';
import * as betaBaskets from './endpoints/betaBaskets';
import * as bottomSheet from './endpoints/bottomSheet';
import * as brand from './endpoints/brand';
import * as dismissibleMessaging from './endpoints/dismissibleMessaging';
import * as experiments from './endpoints/experiments';
import * as favorites from './endpoints/favorites';
import * as featureFlags from './endpoints/featureFlags';
import * as grace from './endpoints/grace';
import * as idMeVerification from './endpoints/idMeVerification.js';
import * as mealAddons from './endpoints/mealAddons';
import * as mealAddonSelections from './endpoints/mealAddonSelections';
import * as mealNotes from './endpoints/mealNotes';
import * as meals from './endpoints/meals';
import * as mealSelections from './endpoints/mealSelections';
import * as menus from './endpoints/menus';
import * as menuSearch from './endpoints/menuSearch';
import * as nutritionalFacts from './endpoints/nutritionalFacts';
import * as offerRewards from './endpoints/offerRewards';
import * as optIns from './endpoints/optIns';
import * as optInSelections from './endpoints/optInSelections';
import * as partnerDeals from './endpoints/partnerDeals';
import * as pastReceipts from './endpoints/pastReceipts';
import * as pauseReasons from './endpoints/pauseReasons';
import * as quickFilters from './endpoints/quickFilters';
import * as restartCampaign from './endpoints/restartCampaign';
import * as reviews from './endpoints/reviews';
import * as sendABox from './endpoints/sendABox';
import * as shoppingListMeals from './endpoints/shoppingListMeals';
import * as signUp from './endpoints/signUp';
import * as streakOffers from './endpoints/streakOffers';
import * as suggestedPairings from './endpoints/suggestedPairings';
import * as user from './endpoints/user';
import * as userMessages from './endpoints/userMessages';

export default {
  ...addresses,
  ...baskets,
  ...behavioralDiscounts,
  ...betaBaskets,
  ...bottomSheet,
  ...brand,
  ...dismissibleMessaging,
  ...experiments,
  ...favorites,
  ...featureFlags,
  ...grace,
  ...idMeVerification,
  ...meals,
  ...mealAddons,
  ...mealAddonSelections,
  ...mealNotes,
  ...mealSelections,
  ...menus,
  ...menuSearch,
  ...nutritionalFacts,
  ...offerRewards,
  ...optIns,
  ...optInSelections,
  ...partnerDeals,
  ...pastReceipts,
  ...pauseReasons,
  ...quickFilters,
  ...reviews,
  ...restartCampaign,
  ...sendABox,
  ...shoppingListMeals,
  ...signUp,
  ...streakOffers,
  ...suggestedPairings,
  ...user,
  ...userMessages,
};
