import pick from 'lodash/pick';

import { decamelize } from 'helpers/camelize';

import {
  fetchWrapper,
  handleResponse,
  buildOpts,
  hcUrl,
  transformNsoMenuResponse,
  transformNsoBoxResponse,
  transformNsoBoxWeeklyBasket,
  formatBody,
  objectToQueryString,
} from '../helpers';

const getNsoUpcomingMenus = async ({ params }) => {
  const { mealPlan } = params;

  const response = await fetchWrapper(
    hcUrl(`/send_a_box/menus?meal_plan=${mealPlan}`),
    buildOpts()
  );

  return handleResponse(response);
};

const getNsoMenu = async ({ params }) => {
  const { menuSlug, mealPlan } = params;

  const response = await fetchWrapper(
    hcUrl(`/send_a_box/menus/${menuSlug}?meal_plan=${mealPlan}`),
    buildOpts()
  );
  return handleResponse(response, transformNsoMenuResponse);
};

const createNsoRecipientInfo = async ({ params }) => {
  const { boxId, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl('/send_a_box/recipients/'),
    buildOpts({
      method: 'POST',
      body: formatBody(
        {
          recipient: restParams,
          boxId,
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const updateNsoBoxRecipientInfo = async ({ params }) => {
  const { boxId, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/recipients/${restParams.id}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody(
        {
          recipient: restParams,
          boxId,
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const getNsoBoxRecipientInfo = async ({ params }) => {
  const response = await fetchWrapper(hcUrl(`/send_a_box/recipients/${params.id}`), buildOpts());
  return handleResponse(response);
};

const createNsoBox = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/send_a_box/boxes'),
    buildOpts({
      method: 'POST',
      body: formatBody(
        {
          box: params,
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const updateNsoBox = async ({ params }) => {
  const { boxId, menuSlug, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/boxes/${boxId}?menu_id=${menuSlug}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody(
        {
          box: restParams,
        },
        true
      ),
    })
  );
  return handleResponse(response, transformNsoBoxResponse);
};

const getNsoBoxes = async () => {
  const response = await fetchWrapper(hcUrl('/send_a_box/boxes'), buildOpts());
  return handleResponse(response);
};

const getNsoBox = async ({ params: { boxId } }) => {
  const response = await fetchWrapper(hcUrl(`/send_a_box/boxes/${boxId}`), buildOpts());
  return handleResponse(response, transformNsoBoxResponse);
};

const getNsoMenuCategories = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/menu_categories?${objectToQueryString(decamelize(params, true))}`),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response);
};

const destroyNsoBox = async ({ params: { boxId } }) => {
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/boxes/${boxId}`),
    buildOpts({
      method: 'DELETE',
      body: formatBody({ boxId }),
    })
  );
  return handleResponse(response);
};

const submitNsoOrder = async ({ params }) => {
  const { boxId, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/boxes/${boxId}/submit_order`),
    buildOpts({
      method: 'POST',
      body: formatBody(
        {
          ...restParams,
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const getNsoReceipt = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/receipt?${objectToQueryString(decamelize(params, true))}`),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response);
};

const setNsoBoxConfirmed = async ({ params }) => {
  const { boxId, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/boxes/${boxId}/confirm_order`),
    buildOpts({
      method: 'POST',
      body: formatBody(
        {
          ...restParams,
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const getBoxWeeklyBasket = async ({ params: { boxId } }) => {
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/box_weekly_baskets/${boxId}`),
    buildOpts()
  );
  return handleResponse(response, transformNsoBoxWeeklyBasket);
};

const updateBoxWeeklyBasket = async ({ params }) => {
  const { boxId, menuSlug, ...restParams } = params;

  const response = await fetchWrapper(
    hcUrl(`/send_a_box/box_weekly_baskets/${boxId}?menu_id=${menuSlug}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody(
        {
          basket: restParams,
        },
        true
      ),
    })
  );
  return handleResponse(response, transformNsoBoxWeeklyBasket);
};

const addNsoReview = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/send_a_box/reviews'),
    buildOpts({
      method: 'POST',
      body: formatBody(params),
    })
  );

  return handleResponse(response);
};

const updateNsoReview = async ({ params: { reviewId, ...restParams } }) => {
  const reviewParams = pick(restParams, ['review.recipeRating', 'review.comment']);
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/reviews/${reviewId}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody(reviewParams),
    })
  );

  return handleResponse(response);
};

const reviewsTransformer = response => ({ reviews: response });

const getNsoPendingReviews = async ({ params }) => {
  const { boxHashId } = params;
  const response = await fetchWrapper(
    hcUrl(`/send_a_box/reviews/pending?box_hash_id=${boxHashId}`),
    buildOpts()
  );

  return handleResponse(response, reviewsTransformer);
};

export {
  getNsoUpcomingMenus,
  getNsoMenu,
  createNsoRecipientInfo,
  updateNsoBoxRecipientInfo,
  getNsoBoxRecipientInfo,
  createNsoBox,
  updateNsoBox,
  getNsoBoxes,
  getNsoBox,
  getNsoMenuCategories,
  destroyNsoBox,
  submitNsoOrder,
  getNsoReceipt,
  setNsoBoxConfirmed,
  getBoxWeeklyBasket,
  updateBoxWeeklyBasket,
  addNsoReview,
  updateNsoReview,
  getNsoPendingReviews,
};
