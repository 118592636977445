import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  formatBody,
} from 'services/RequestService/helpers';

export const createCustomerReport = async () => {
  const response = await fetchWrapper(
    hcUrl('/grace/customer_reports'),
    buildOpts({
      method: 'POST',
    })
  );
  return handleResponse(response);
};

export const findCustomerReport = async () => {
  const response = await fetchWrapper(
    hcUrl('/grace/customer_report'),
    buildOpts({
      method: 'POST',
    })
  );
  return handleResponse(response);
};

export const createCustomerReportAction = async ({ customerReportId, reportStageOptionId }) => {
  const response = await fetchWrapper(
    hcUrl(`/grace/customer_reports/${customerReportId}/actions`),
    buildOpts({
      method: 'POST',
      body: formatBody({ reportStageOptionId }),
    })
  );
  return handleResponse(response);
};

export const closeCustomerReport = async ({ customerReportId }) => {
  const response = await fetchWrapper(
    hcUrl(`/grace/customer_reports/${customerReportId}/`),
    buildOpts({
      method: 'DELETE',
      body: formatBody({ customerReportId }),
    })
  );
  return handleResponse(response);
};
