/* global localStorage */ //eslint-disable-line no-redeclare
import once from 'lodash/once';

const PREFIX = 'HomeChefSession';

const isAvailable = once(() => {
  try {
    const key = '__TEST__';
    localStorage.setItem(key, key);
    localStorage.getItem(key);
    localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
});

export const getItem = key => {
  if (!isAvailable()) {
    return;
  }
  return localStorage.getItem(key);
};

export const setItem = (key, val) => {
  if (!isAvailable()) {
    return;
  }
  localStorage.setItem(key, val);
};

export const removeItem = key => {
  if (!isAvailable()) {
    return;
  }
  localStorage.removeItem(key);
};

const keyWithPrefix = key => `${PREFIX}_${key}`;
const getWithPrefix = key => getItem(keyWithPrefix(key));
const setWithPrefix = (key, value) => setItem(keyWithPrefix(key), value);

export default {
  isAvailable,
  write: (key, value) => {
    try {
      const stringified = JSON.stringify(value);
      setWithPrefix(key, stringified);
    } catch {
      return;
    }
  },
  delete: key => {
    try {
      removeItem(keyWithPrefix(key));
    } catch {
      return;
    }
  },
  read: key => {
    const value = getWithPrefix(key);
    try {
      return JSON.parse(value);
    } catch {
      return;
    }
  },
};
