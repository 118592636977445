import { fetchWrapper, handleResponse, hcUrl, buildOpts } from 'services/RequestService/helpers';

export const getOfferRewards = async () => {
  const response = await fetchWrapper(hcUrl('/offer_rewards/weekly_baskets'), buildOpts());
  return handleResponse(response);
};

export const getVoucherDetails = async () => {
  const response = await fetchWrapper(hcUrl('/offer_rewards/voucher_details'), buildOpts());
  return handleResponse(response);
};
